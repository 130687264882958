import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import ExternalLink from "../components/blog/ExternalLink";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlogPost = ({location}) =>{
    return <BlogPostWrapper>
        <StyledMainContent>
        <SEO
            title="9 Pro Tips On How To Save Money Grocery Shopping"
            description="By implementing one or more of our tips for saving money on grocery shopping, we are sure that your grocery trips will become way less stressful."
            location={location}
            pageType="article"
        />
        <HeroSection>
            <BlogTitle>
                9 Pro Tips On How To Save Money Grocery Shopping
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/9-Pro-Tips-On-How-To-Save-Money-Grocery-Shopping.jpg" alt="9 Pro Tips On How To Save Money Grocery Shopping"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@eduschadesoares?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Eduardo Soares</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/grocery-bill?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            Food prices have gone up. Even if you don’t know the official statistics for your region (for example, in the United States, grocery prices increased <ExternalLink to= "https://www.bls.gov/opub/ted/2022/food-prices-up-10-8-percent-for-year-ended-april-2022-largest-12-month-increase-since-november-1980.htm#:~:text=SUBSCRIBE-,Food%20prices%20up%2010.8%20percent%20for%20year%20ended%20April%202022,month%20increase%20since%20November%201980&text=For%20the%20year%20ended%20April,Urban%20Consumers%20increased%208.3%20percent.">10.8% from April 2021 to April 2022</ExternalLink> - the biggest increase since 1980), you must have noticed your wallet taking a bigger hit than usual at your local grocery store.
        </Paragraph>
        <Paragraph>
            With the economy in such a precarious state as it is, it is only natural to look for ways to cut costs without having to sacrifice the quality of your diet. No one likes walking into a grocery shop and wondering whether they will have enough on their bank account to pay for everything they need.
        </Paragraph>
        <Paragraph>
            So, to ease all of our struggles and anxiety, we’ve compiled a list of tried and true ways you can save money while grocery shopping.
        </Paragraph>
        <Paragraph>
            Feel free to try all of these <InternalLink to="/grocery-shopping-tips/">grocery shopping tips</InternalLink>, or simply pick and choose which ones you think will work best for you.
        </Paragraph>
        <BlogHeading2>
            1. Make a shopping list - and stick to it
        </BlogHeading2>
        <Paragraph>
            Possibly the most important item on our list is this one - you have to have a plan. Before you set off to the grocery store, you should make a list of all the groceries you need. Even better if you take the time to plan out your meals for the next week (or two weeks, or more) and write down all the ingredients you will need for them.
        </Paragraph>
        <Paragraph>
            Do not, and we repeat, <em>do not</em>, waver from this plan. An impulse buy is one of the easiest ways of going over your grocery budget.
        </Paragraph>
        <Paragraph>
            Save yourself the headache - make a grocery list with all the items you need, and don’t buy anything you didn't plan for.
        </Paragraph>
        <BlockQuote>
            Our grocery app, <InternalLink to="/#download-section">Going Shopping</InternalLink>, will make it super easy for you to create a shopping list and carry it with you anywhere you go.
        </BlockQuote>
        <BlogHeading2>
            2. Buy on sale
        </BlogHeading2>
        <Paragraph>
            Okay, so this is a grocery shopping tip that you probably already knew - but it really pays to stay up to date with all the sales in your local grocery stores. Browse through leaflets, online and offline, and stay current on what goes on sale when. Most stores announce their sales in advance, with entire lists of items that will get a price cut. Keep a close eye on your favorite grocery items, the ones you buy frequently, and stock up on them when they’re on sale (if you can store them for a while, that is).
        </Paragraph>
        <Paragraph>
            This can even help you in your meal planning - if there’s a sale on pasta next week, why not try that new Italian recipe you’ve been eyeing for a while now? An effective meal plan will also help you cut down on food waste.
        </Paragraph>
        <BlogHeading2>
            3. Buy in bulk
        </BlogHeading2>
        <Paragraph>
            Not all stores allow their customers to buy in bulk for a lower price, but you can find specialized wholesale stores here and there. <ExternalLink to="https://www.costco.com/">Costco</ExternalLink>, for example, offers a membership for their wholesale club. The membership isn’t free, unfortunately, but for large families and people looking to save on items they can store in large quantities, it can be a life-changer.
        </Paragraph>
        <Paragraph>
            Of course, buying anything in a large quantity requires paying a larger sum of money up front, so it may not be a good choice for everyone, depending on your finances.
        </Paragraph>
        <BlogHeading2>
            4. Sign up for loyalty programs and clip coupons
        </BlogHeading2>
        <Paragraph>
            No matter where you live, there must be supermarket chains that offer loyalty programs. Completely for free, you can sign up for a loyalty card of the stores you visit most often - you might even be surprised at how fast they start paying off! Each purchase will earn you some type of a reward in a loyalty program, whether discounts or card points you can spend instead of money, or something else entirely.
        </Paragraph>
        <Paragraph>
            In addition, or even if you don’t sign up for a loyalty program, you can look for discount coupons. They can show up in store leaflets, Sunday papers, or somewhere similar. Coupon clipping may sound tedious, but it can definitely help with limiting your grocery spending. So don’t hesitate to grab those scissors the next time you spot a good offer for your local supermarket.
        </Paragraph>
        <BlogHeading2>
            5. Look for generic store brands
        </BlogHeading2>
        <Paragraph>
            Most, if not all, large supermarket chains have their own generic brands of popular food and household items. A store brand item may not be sitting front and center on a shelf because it costs less than its more well-known counterparts <ExternalLink to="https://www.consumerreports.org/cro/magazine/2012/10/store-brand-vs-name-brand-taste-off/index.htm">up to 25% less</ExternalLink>, according to research), but it is worth looking into.
        </Paragraph>
        <Paragraph>
            There is a reason why <ExternalLink to="https://www.npr.org/sections/money/2014/07/25/334459041/when-do-chefs-and-doctors-buy-generic">most chefs buy generic</ExternalLink> baking mixes, powdered sugar, brown sugar, baking soda, flour, teas, and similar items - they perform and taste just as well as other brands, but are so much cheaper to get.
        </Paragraph>
        <ImageWithCaption>
            <StaticImage src="../assets/images/blogs/post-2022-2-image2.jpg" alt="A deserted aisle in a large wholesale store with one worker checking the shelves."/>
            <em>Photo by <ExternalLink to="https://unsplash.com/@densiq?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Dennis Siqueira</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/grocery-bill?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading2>
            6. Compare store prices
        </BlogHeading2>
        <Paragraph>
            If you are fortunate enough to live in a location near more than one grocery store, you might have the luxury of comparing prices of the items you buy most often. You can do so in person, but maybe even online, if the stores offer online shopping.
        </Paragraph>
        <Paragraph>
            If you find that two or three stores have a better deal, you might need to split your grocery trip between those stores. In that case, don’t forget to take into account the price of gas you will need to circle them all - with the increasing costs of gas, it may not be worth it to travel to a different store just to get a food item at a slightly lower price.
        </Paragraph>
        <BlogHeading2>
            7. Stay away from pre-packaged stuff
        </BlogHeading2>
        <Paragraph>
            Shredded cheese, washed and chopped salad greens, peeled pomegranate - all of those prepared and packaged items are time savers, for sure. But they also add a lot more to your grocery receipt.
        </Paragraph>
        <Paragraph>
            If you want to go for extra savings, avoid pre-packaged items and opt for their whole, untouched counterparts. The same goes for processed food, which can leave a significant dent in your budget. Fresh produce and fresh fruit is way better for your health anyway.
        </Paragraph>
        <BlogHeading2>
            8. Shop on a full stomach - and by yourself
        </BlogHeading2>
        <Paragraph>
            The number one rule of grocery shopping is to not do it when hungry. Trust us, just don’t. Your eyes and empty stomach will want it all, and your bank account will not appreciate it. Even if you have a small snack before you set off, anything is better than nothing to prevent an impulse purchase.
        </Paragraph>
        <Paragraph>
            Additionally, going by yourself will not only save you time, but most likely save you money as well. This is especially true if you have children who get easily distracted by colorful packaging and get the sudden urge for three different types of cereal.
        </Paragraph>
        <BlogHeading2>
            9. Bring your own grocery bag
        </BlogHeading2>
        <Paragraph>
            Finally, another simple way to reduce your everyday spending is to always bring a reusable grocery bag with you. Your budget will be grateful for two reasons:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>
                You won’t have to purchase grocery bags at the cash register.
            </UnorderedListItem>
            <UnorderedListItem>
                You might get a discount for bringing in a reusable bag (such as in <ExternalLink to="https://www.wholefoodsmarket.com/">Whole Foods</ExternalLink>, for example).
            </UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Plus, it’s good for the environment!
        </Paragraph>
        <BlogHeading2>
            Conclusion
        </BlogHeading2>
        <Paragraph>
            By implementing one or more of our tips for saving money grocery shopping, we are sure that your grocery trips will become way less stressful.
        </Paragraph>
        <Paragraph>
            The bottom line is that planning is key. You need it for setting up a shopping list, scouting for all the sales, discounts, and bulk offers, and signing up for loyalty programs that will benefit you. The better you plan out your shopping trips, the less surprised you will be by the grocery bill.
        </Paragraph>
        <Paragraph>
            P.S. Don’t forget to <InternalLink to="/#download-section">download our free grocery list app</InternalLink> to start planning today!
        </Paragraph>
    </StyledMainContent>
    </BlogPostWrapper>
}

export default BlogPost;